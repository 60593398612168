html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
strong,
abbr,
acronym,
blockquote,
q,
cite,
ins,
del,
dfn,
a,
div,
span,
pre,
hr,
address,
br,
b,
i,
sub,
big,
small,
tt,
table,
tr,
caption,
thead,
tbody,
tfoot,
col,
colgroup,
form,
input,
label,
textarea,
button,
fieldset,
legend,
select,
option,
ul,
ol,
li,
dl,
dt,
dd,
code,
var,
samp,
img,
object,
param,
map,
area {
  padding: 0;
  margin: 0;
  border: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
header,
footer,
section,
aside,
figure,
figcaption,
input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type=button],
input[type=submit],
button {
  cursor: pointer;
}
button {
  border: 0;
}
:focus {
  outline: none;
}
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
  outline: none;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
input,
textarea {
  resize: none;
  user-select: text;
}
::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}
a {
  text-decoration: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
@font-face {
  font-family: 'SFPro';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/SFProDisplay-Black.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/SFProDisplay-BlackItalic.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/SFProDisplay-Bold.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/SFProDisplay-BoldItalic.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/SFProDisplay-Light.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/SFProDisplay-LightItalic.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/SFProDisplay-Medium.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/SFProDisplay-MediumItalic.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/SFProDisplay-Regular.woff2") format('woff2');
}
@font-face {
  font-family: 'SFPro';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("../fonts/SFProDisplay-RegularItalic.woff2") format('woff2');
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.owl-item {
  transform: translateZ(0);
}
img {
  display: block;
}
button {
  background: transparent;
}
input,
textarea {
  font-family: 'SFPro';
  font-weight: 400;
  -webkit-appearance: none;
  border-radius: 0;
  color: #4d4d4d;
}
html,
body {
  color: #4d4d4d;
  height: 100%;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  font-family: 'SFPro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
* {
  margin: 0;
  padding: 0;
}
header,
footer,
section {
  max-width: 100vw;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  max-width: 100vw;
  overflow-x: hidden;
}
.b-wrapper {
  width: 100%;
  display: table;
  height: 100%;
  padding-top: 92px;
  max-width: 100vw;
  overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
  .b-wrapper {
    padding-top: 40px;
  }
}
.content {
  display: table-row;
  width: 100%;
  height: 100%;
}
.section {
  width: 100%;
}
.hidden {
  display: none !important;
}
.container {
  max-width: 1164px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}
.row {
  margin-bottom: 0;
}
.col {
  padding: 0 15px;
}
@media only screen and (max-width: 600px) {
  .col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
header,
section {
  margin-bottom: 112px;
}
@media only screen and (max-width: 767px) {
  header,
  section {
    margin-bottom: 50px;
  }
}
span {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
}
strong {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: 700;
}
h1 {
  font-size: 48px;
  line-height: 120%;
  letter-spacing: 0.41px;
  margin-bottom: 32px;
  font-weight: 700;
  color: #20233f;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 24px;
    line-height: 141.2%;
    margin-bottom: 16px;
  }
}
h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: 0.41px;
  color: #20233f;
  margin-bottom: 24px;
}
@media only screen and (max-width: 600px) {
  h2 {
    font-size: 24px;
    line-height: 141.2%;
    margin-bottom: 16px;
  }
}
h3 {
  font-weight: 600;
  font-size: 32px;
  line-height: 141.2%;
  letter-spacing: 0.41px;
  color: #20233f;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  h3 {
    font-size: 24px;
    line-height: 141.2%;
    margin-bottom: 24px;
  }
}
h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 141.2%;
  letter-spacing: 0.41px;
  color: #20233f;
  margin-bottom: 32px;
}
@media only screen and (max-width: 600px) {
  h4 {
    font-size: 20px !important;
    line-height: 141.2%;
  }
}
p {
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  p {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.41px;
  }
}
.description {
  font-size: 24px;
  line-height: 141.2%;
  letter-spacing: 0.41px;
}
@media only screen and (max-width: 600px) {
  .description {
    font-size: 20px;
    line-height: 140%;
  }
}
ul.custom-list li {
  position: relative;
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 8px;
  padding-left: 24px;
}
@media only screen and (max-width: 600px) {
  ul.custom-list li {
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 12px;
  }
}
ul.custom-list li:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  border: 2px solid #5ec4c6;
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
@media only screen and (max-width: 600px) {
  ul.custom-list li:before {
    top: 4px;
  }
}
.btn-custom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  padding: 8px 20px;
  color: #4d4d4d;
  border-radius: 40px;
  transition: all 0.3s ease;
  border: 1px solid #3ebb78;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .btn-custom {
    display: flex;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
.btn-custom:hover {
  color: #fff;
  background-color: #3ebb78;
}
.btn-custom--white {
  background-color: #fff;
}
.btn-custom--white:hover {
  background-color: #1dac5f;
  border: 1px solid #1dac5f;
}
.btn-custom--big {
  padding: 16px 32px;
}
.btn-custom--green {
  color: #fff;
  padding: 16px 32px;
  background-color: #3ebb78;
}
.btn-custom--green:hover {
  background-color: #1dac5f;
  border: 1px solid #1dac5f;
}
.btn-custom--green:focus,
.btn-custom--green:active {
  background-color: #1d8c50;
  border: 1px solid #1d8c50;
}
.btn-custom--border {
  color: #fff;
  padding: 16px 32px;
  border: 1px solid #5c5f7d;
}
.btn-custom--border:hover {
  background-color: #fff;
  color: #20233f;
}
.btn-custom--tab {
  font-weight: 600;
  font-size: 16px;
  line-height: 141.2%;
  letter-spacing: 0.41px;
  color: #20233f;
  border: 1px solid #3ebb78;
  padding: 12px 24px;
  background-color: transparent;
  margin-right: 12px;
  margin-bottom: 12px;
  margin-top: 12px;
}
@media only screen and (max-width: 600px) {
  .btn-custom--tab {
    width: 48%;
    margin: 8px 0;
  }
}
.btn-custom--tab:last-child {
  margin-right: 0;
}
.btn-custom--tab:hover {
  color: #3ebb78;
  background-color: transparent;
}
.btn-custom--tab.active {
  background-color: #3ebb78 !important;
  color: #fff !important;
  border: 1px solid #3ebb78 !important;
}
.collapsible {
  border: none;
  box-shadow: none;
}
@media only screen and (max-width: 600px) {
  .collapsible {
    margin-bottom: 0;
  }
}
.collapsible li.collapsible-item {
  margin-bottom: 16px;
  border: 1px solid #e1e1e5;
  border-radius: 12px;
  background-color: #fff;
  box-sizing: border-box;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 600px) {
  .collapsible li.collapsible-item {
    margin-bottom: 8px;
  }
}
.collapsible li.collapsible-item.active .collapsible-header p {
  color: #20233f;
}
.collapsible li.collapsible-item.active .collapsible-arrow {
  transform: rotate(180deg) translateY(50%);
}
.collapsible li.collapsible-item:hover {
  box-shadow: 0px 16px 16px rgba(142,142,142,0.1);
}
.collapsible li.collapsible-item:hover .collapsible-arrow path {
  fill: #4c4c4c;
}
.collapsible li.collapsible-item .collapsible-header {
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  padding: 24px 52px 24px 24px;
  box-shadow: none;
  background-color: transparent;
}
@media only screen and (max-width: 600px) {
  .collapsible li.collapsible-item .collapsible-header {
    padding: 16px 48px 16px 16px;
  }
}
.collapsible li.collapsible-item .collapsible-header p {
  margin-bottom: 0;
  font-weight: 600;
  color: #4d4d4d;
}
.collapsible li.collapsible-item .collapsible-body {
  border: none;
  padding: 0 24px 8px 24px;
}
.collapsible li.collapsible-item .collapsible-body li {
  margin-bottom: 16px;
}
.collapsible li.collapsible-item .collapsible-title {
  width: calc(100% - 64px);
}
.collapsible li.collapsible-item .collapsible-icon-wrap {
  width: 40px;
  height: 40px;
  margin-right: 24px;
}
.collapsible li.collapsible-item .collapsible-icon-wrap img {
  max-width: 100%;
  max-height: 100%;
}
.collapsible li.collapsible-item .collapsible-arrow {
  position: absolute;
  width: 20px;
  height: 14px;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}
.collapsible li.collapsible-item .collapsible-arrow path {
  transition: all 0.3s ease;
}
.s-contact .form-wrp {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 48px;
}
@media only screen and (max-width: 600px) {
  .s-contact .form-wrp {
    flex-wrap: wrap;
  }
}
.s-contact .form-group {
  width: 100%;
}
.s-contact .form-group_half {
  width: 48%;
}
@media only screen and (max-width: 600px) {
  .s-contact .form-group_half {
    width: 100%;
  }
}
.s-contact label {
  font-size: 20px;
  line-height: 140%;
  color: #fff;
  margin-bottom: 8px;
  display: block;
}
@media only screen and (max-width: 600px) {
  .s-contact label {
    font-size: 16px;
    line-height: 160%;
  }
}
.s-contact label span {
  color: #ee4865;
}
.s-contact .wrp-input {
  padding-bottom: 24px;
  position: relative;
}
.s-contact .wrp-input input {
  height: 60px;
}
.s-contact .wrp-input textarea {
  height: 120px;
}
.s-contact .wrp-input textarea,
.s-contact .wrp-input input {
  width: 100%;
  border-radius: 8px;
  background-color: #2b2e49;
  transition: all 0.3s ease;
  padding: 16px 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #fff;
  border: 1px solid #2b2e49;
}
.s-contact .wrp-input textarea.invalid,
.s-contact .wrp-input input.invalid {
  border: 1px solid #ee4865 !important;
}
.s-contact .wrp-input textarea.valid,
.s-contact .wrp-input input.valid {
  border: 1px solid #3ebb78 !important;
}
.s-contact .wrp-input textarea:hover,
.s-contact .wrp-input input:hover {
  border: 1px solid #646573;
}
.s-contact .wrp-input textarea:focus,
.s-contact .wrp-input input:focus {
  background-color: #363952;
}
.s-contact .wrp-input textarea::placeholder,
.s-contact .wrp-input input::placeholder {
  color: #646573;
  font-weight: 400 !important;
}
.s-contact .wrp-input textarea:-webkit-autofill,
.s-contact .wrp-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #2b2e49 inset;
}
.s-contact .wrp-input textarea:-webkit-autofill::first-line,
.s-contact .wrp-input input:-webkit-autofill::first-line {
  font-family: 'SFPro';
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}
.s-contact .form-error {
  position: absolute;
  left: 0;
  bottom: -2px;
  color: #ee4865;
}
.s-contact .preloader-wrapper {
  display: none;
}
@media only screen and (max-width: 600px) {
  .modal {
    width: 90%;
  }
}
.modal .cross-link {
  position: absolute;
  top: 26px;
  right: 25px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal .cross-link path {
  fill: #e1e1e5;
  transition: all 0.3s ease;
}
.modal .cross-link:hover path {
  fill: #4d4d4d;
}
@media only screen and (max-width: 600px) {
  .modal .btn-custom {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 12px;
  }
}
.modal__title {
  margin-bottom: 24px;
  text-align: center;
}
.modal__text {
  text-align: center;
}
.modal__thanks {
  max-width: 536px;
  background: #fff;
  border-radius: 8px;
}
.modal .modal-content {
  padding: 56px 46px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 600px) {
  .modal .modal-content {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.copy-link__wrap {
  position: relative;
  display: flex;
}
.copy-link__wrap .copy-block {
  margin-left: 8px;
  cursor: pointer;
  transition: 0.2s ease;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5fcf8;
  margin-top: -2px;
}
.copy-link__wrap .copy-block:hover .tooltip__text {
  display: block;
  position: absolute;
  top: 40px;
  left: -55px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #3ebb78;
  padding: 5px 10px 5px;
  width: 140px;
}
@media only screen and (max-width: 991px) {
  .copy-link__wrap .copy-block:hover .tooltip__text {
    display: none !important;
  }
}
.copy-link__wrap .copy-block.active-copy .copy__text {
  display: block;
  position: absolute;
  top: 40px;
  left: -12px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background-color: #3ebb78;
  padding: 5px 10px 6px;
}
.copy-link__wrap .copy-block.active-copy .tooltip__text {
  display: none;
}
.copy-link__wrap .copy-block.active svg {
  transform: translate(-50%, -50%) scale(0.9, 0.9);
}
.copy-link__wrap .copy-block .tooltip__text,
.copy-link__wrap .copy-block .copy__text {
  display: none;
  margin: 0;
}
.copy-link__wrap .copy-block svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.enter-y {
  transform: translateY(50px) rotate(2deg);
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.enter {
  transition: all 1.2s cubic-bezier(0.37, 0.01, 0, 0.98);
  opacity: 0;
}
.seen .enter {
  opacity: 1;
  transition-delay: 0.3s !important;
}
.seen .enter-y {
  opacity: 1;
  transform: translate(0) scale(1) rotate(0);
}
.seen .delay-2 {
  transition-delay: 0.1s !important;
}
.seen .delay-3 {
  transition-delay: 0.15s !important;
}
.seen .delay-4 {
  transition-delay: 0.2s !important;
}
.seen .delay-5 {
  transition-delay: 0.25s !important;
}
.seen .delay-6 {
  transition-delay: 0.3s !important;
}
.seen .delay-7 {
  transition-delay: 0.35s !important;
}
.seen .delay-8 {
  transition-delay: 0.4s !important;
}
.seen .delay-9 {
  transition-delay: 0.45s !important;
}
.top-menu {
  height: auto;
  line-height: inherit;
  background-color: transparent;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  box-shadow: none;
  transition: all 0.3s ease;
}
.top-menu.not-top {
  background-color: #fff;
}
.top-menu .nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;
  max-width: 1164px;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 992px) {
  .top-menu .nav-wrapper {
    padding: 12px 24px 12px 16px;
  }
}
.top-menu .brand-logo {
  position: relative;
  width: 130px;
}
.top-menu .brand-logo img {
  max-width: 100%;
}
.top-menu .top-menu-link {
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  margin: 0 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #4d4d4d;
  padding: 0;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 1200px) {
  .top-menu .top-menu-link {
    margin: 0 12px;
  }
}
.top-menu .top-menu-link:hover {
  color: #20233f;
}
.top-menu .top-menu-link--arrow {
  padding-right: 28px;
  position: relative;
}
.top-menu .top-menu-link--arrow:after {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 2px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../img/arrow-menu-dropdown.svg") center no-repeat;
}
.top-menu .btn-custom {
  margin-top: 0;
  padding: 8px 20px;
}
.top-menu .dropdown-content {
  padding: 32px 40px 32px 32px;
  border-radius: 16px;
  background-color: #fff;
  width: auto !important;
  top: 90px !important;
  overflow: visible;
}
.top-menu .dropdown-content:before {
  content: '';
  display: block;
  position: absolute;
  top: -24px;
  left: 40px;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  border-bottom: 12px solid #fff;
}
.top-menu .dropdown-content li {
  background-color: transparent;
  min-height: inherit;
}
.top-menu .dropdown-content li:last-child a {
  margin-bottom: 0;
}
.top-menu .dropdown-content a {
  padding: 0 24px 0 0;
  margin-bottom: 16px;
  position: relative;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #4d4d4d;
  transition: all 0.3s ease;
  background-color: transparent;
  display: inline-block;
}
.top-menu .dropdown-content a:after {
  content: ' →';
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.top-menu .dropdown-content a:hover {
  color: #20233f;
}
.top-menu .dropdown-content a:hover:after {
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .desctop-menu {
    display: none;
  }
}
.mobile-menu {
  display: none;
  height: 72px;
}
@media only screen and (max-width: 600px) {
  .mobile-menu.not-top {
    height: 60px;
  }
}
.mobile-menu .nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobile-menu .nav-wrapper .sidenav-trigger {
  line-height: inherit;
  height: 24px;
  margin-right: 0;
}
.mobile-menu .nav-wrapper .brand-logo {
  position: static;
  transform: none;
}
.mobile-menu .nav-wrapper .brand-logo svg {
  height: 48px;
}
@media only screen and (max-width: 992px) {
  .mobile-menu {
    display: block;
  }
}
.sidenav {
  background-color: #fff;
  width: 400px;
}
@media only screen and (max-width: 600px) {
  .sidenav {
    width: 100%;
  }
}
.sidenav .sidenav-header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 12px 24px 12px 16px;
}
.sidenav .sidenav-header .sidenav-cross {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidenav .sidenav-header .brand-logo svg {
  height: 48px;
}
.sidenav .sidenav-header .sidenav-close i {
  color: #fff;
}
.sidenav .sidenav-content {
  padding-top: 80px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 500px) {
  .sidenav .sidenav-content {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 340px) {
  .sidenav .sidenav-content {
    padding-top: 40px;
  }
}
.sidenav .sidenav-content .inquire-btn {
  display: flex;
  justify-content: center;
}
.sidenav .sidenav-content .btn-custom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  padding: 8px 20px;
  width: 300px;
  color: #4d4d4d;
  border-radius: 40px;
  transition: all 0.3s ease;
  border: 1px solid #3ebb78;
}
.sidenav .sidenav-content .btn-custom:hover {
  color: #fff;
  background-color: #3ebb78;
}
.sidenav .sidenav-content .btn-custom--green {
  color: #fff;
  padding: 16px 32px;
  background-color: #3ebb78;
}
.sidenav .sidenav-content .btn-custom--green:hover {
  background-color: #1dac5f;
  border: 1px solid #1dac5f;
}
.sidenav .sidenav-content .btn-custom--green:focus,
.sidenav .sidenav-content .btn-custom--green:active {
  background-color: #1d8c50;
  border: 1px solid #1d8c50;
}
.sidenav .sidenav-content .anqors-wrap {
  width: 100%;
}
.sidenav .sidenav-content li {
  line-height: inherit;
  height: auto;
}
.sidenav .sidenav-content .top-menu-link {
  height: auto;
  padding: 0;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 24px;
  line-height: 141.2%;
  color: #4d4d4d;
  transition: all 0.3s ease;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .sidenav .sidenav-content .top-menu-link {
    color: #20233f;
  }
}
@media only screen and (max-width: 500px) {
  .sidenav .sidenav-content .top-menu-link {
    margin-bottom: 30px;
  }
}
.sidenav .collapsible {
  background-color: transparent;
}
.sidenav .collapsible li {
  background-color: transparent;
}
.sidenav .collapsible .active .collapsible-header .top-menu-link:after {
  transform: rotate(180deg);
}
.sidenav .collapsible .collapsible-body .top-menu-link {
  display: block;
  margin-bottom: 40px;
}
.sidenav .collapsible .collapsible-header {
  display: flex;
  justify-content: center;
  background-color: transparent;
}
.sidenav .collapsible .collapsible-header .top-menu-link {
  padding-right: 28px;
  position: relative;
}
.sidenav .collapsible .collapsible-header .top-menu-link:after {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  top: 4px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../img/arrow-menu-dropdown.svg") center no-repeat;
}
.mobile-logo {
  display: none;
}
.s-header {
  position: relative;
  z-index: 2;
}
.s-header__content {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .s-header__content {
    flex-wrap: wrap;
  }
}
.s-header__text-wrap {
  width: 540px;
  padding-top: 124px;
  padding-bottom: 124px;
}
@media only screen and (max-width: 1200px) {
  .s-header__text-wrap {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 992px) {
  .s-header__text-wrap {
    width: 50%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .s-header__text-wrap {
    width: 100%;
    order: 2;
  }
}
.s-header__image-wrap {
  position: relative;
  width: calc(100% - 540px);
}
@media only screen and (max-width: 1200px) {
  .s-header__image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 992px) {
  .s-header__image-wrap {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .s-header__image-wrap {
    width: 100%;
    order: 1;
  }
}
.s-header__img {
  position: absolute;
  left: -40px;
  top: 0;
}
@media only screen and (max-width: 1200px) {
  .s-header__img {
    max-width: 100%;
    left: 0;
    position: static;
  }
}
@media only screen and (max-width: 767px) {
  .s-header__img {
    max-width: 500px;
    width: 100%;
  }
}
.s-header__img--mob {
  display: none;
}
@media only screen and (max-width: 600px) {
  .s-header__img--mob {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .s-header__img--desctp {
    display: none;
  }
}
.s-contact {
  position: relative;
  padding-top: 280px;
  overflow: hidden;
  margin-bottom: 0;
}
@media only screen and (max-width: 992px) {
  .s-contact {
    padding-top: 140px;
  }
}
.s-contact__wrapper {
  background-color: #20233f;
}
.s-contact__overlay {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #20233f;
  transform: skewY(5deg);
}
@media only screen and (max-width: 992px) {
  .s-contact__overlay {
    top: 50px;
  }
}
.s-contact__overlay img {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.s-contact .form-content {
  position: relative;
  z-index: 2;
  padding-bottom: 64px;
}
.s-contact__title {
  color: #fff;
  text-align: center;
}
.s-contact .description {
  color: #fff;
  text-align: center;
}
.s-contact .bottom-form-wrap {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}
.s-contact .btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-contact .submitButton {
  width: 300px;
}
.s-contact .footer-content {
  position: relative;
  z-index: 2;
}
.s-contact .footer-content__wrap {
  border-top: 2px solid #474960;
  padding-top: 64px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 992px) {
  .s-contact .footer-content__wrap {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .s-contact .footer-content__wrap {
    justify-content: flex-start;
  }
}
.s-footer {
  position: relative;
  background-color: #20233f;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-content__wrap {
  border-top: 2px solid #474960;
  padding-top: 64px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
}
.footer-content__block {
  width: 30%;
  margin-bottom: 26px;
}
.footer-content__block--phones {
  margin-top: 54px;
}
.footer-content__main-block {
  width: 33%;
  padding-right: 20px;
}
@media only screen and (max-width: 992px) {
  .footer-content__main-block {
    padding-right: 0;
    width: 100%;
  }
}
.footer-content__links-block {
  width: 66%;
  display: flex;
  justify-content: space-around;
}
@media only screen and (max-width: 992px) {
  .footer-content__links-block {
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 992px) {
  .footer-content__links-col {
    width: 100%;
    margin-bottom: 24px;
  }
  .footer-content__links-col:last-child {
    margin-bottom: 0;
  }
}
.footer-content__link-header {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 16px;
}
.footer-content__link {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #fff;
  margin-bottom: 16px;
  display: inline-block;
}
.footer-content__link--sub {
  font-size: 20px;
  line-height: 140%;
  color: #fff;
  opacity: 0.8;
  font-weight: 400;
}
.footer-content__loc-title {
  display: flex;
  align-items: center;
}
.footer-content__loc-title p {
  margin: 0;
  font-weight: 700;
  color: #646573;
}
.footer-content__loc-text {
  color: #fff;
  margin-left: 28px;
}
.footer-content__loc-text p {
  margin: 0;
  font-size: 20px;
  line-height: 140%;
}
.footer-content__icon {
  margin-right: 8px;
}
.footer-content__phone {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.footer-content__phone span {
  font-size: 20px;
  line-height: 140%;
  color: #fff;
}
.footer-content__mail {
  display: flex;
  align-items: center;
}
.footer-content__mail span {
  font-size: 20px;
  line-height: 140%;
  color: #fff;
}
.footer-content__rights-links {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .footer-content__rights-links {
    width: 100%;
  }
}
.footer-content__rights-link-wrap {
  display: flex;
  margin-right: 16px;
}
@media only screen and (max-width: 767px) {
  .footer-content__rights-link-wrap {
    width: 100%;
    margin-bottom: 16px;
  }
}
.footer-content__rights-link-wrap:last-child {
  margin-right: 0;
}
.footer-content__rights-wrap {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;
  margin-top: 80px;
}
@media only screen and (max-width: 992px) {
  .footer-content__rights-wrap {
    margin-top: 56px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-content__rights-wrap {
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 40px;
  }
}
.footer-content__copyright {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.41px;
  color: #646573;
}
@media only screen and (max-width: 767px) {
  .footer-content__copyright {
    order: 2;
    margin-top: 40px;
    margin-bottom: 8px;
    margin-right: 0;
    width: 100%;
    text-align: left;
  }
}
.footer-content__privacy {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.41px;
  color: #646573;
  transition: all 0.3s ease;
  text-decoration: none;
}
.footer-content__privacy:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .footer-content__privacy {
    order: 1;
  }
}
.footer-content .copy-link__wrap .copy-block {
  background-color: transparent;
}
.s-program {
  position: relative;
}
.s-program__overlay {
  position: absolute;
  left: 50%;
  top: -140px;
  transform: translateX(-50%);
  display: none;
}
@media only screen and (max-width: 767px) {
  .s-program__overlay {
    display: block;
  }
}
@media only screen and (max-width: 400px) {
  .s-program__overlay {
    left: 5%;
    top: -100px;
  }
}
.s-program__content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 767px) {
  .s-program__content {
    flex-wrap: wrap;
  }
}
.s-program__block {
  width: 48%;
}
@media only screen and (max-width: 767px) {
  .s-program__block {
    width: 100%;
  }
}
.s-program__item {
  display: flex;
  align-items: flex-start;
  margin: 26px 0 52px;
}
@media only screen and (max-width: 767px) {
  .s-program__item {
    margin-bottom: 20px;
  }
}
.s-program__icon-wrap {
  width: 56px;
  height: 56px;
  margin-right: 16px;
}
.s-program__text {
  width: calc(100% - 72px);
}
.s-video {
  position: relative;
  z-index: 2;
}
.s-video__title {
  text-align: center;
  margin-bottom: 0;
}
.s-video .description {
  max-width: 830px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.s-video__video-wrap {
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  width: 730px;
  height: 410px;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  z-index: 3;
  transition: all 1s ease;
  transform: translateY(-50%);
  opacity: 0;
}
@media only screen and (max-width: 767px) {
  .s-video__video-wrap {
    width: 490px;
    height: 272px;
  }
}
@media only screen and (max-width: 600px) {
  .s-video__video-wrap {
    width: 330px;
    height: 185px;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .s-video__video-wrap {
    width: 288px;
    height: 156px;
  }
}
.s-video__video-wrap.active .s-video__overlay-wrap {
  display: none;
}
.s-video__video-wrap.active .s-video__video {
  display: block;
}
.s-video__overlay {
  background-color: rgba(0,0,0,0.16);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.s-video__overlay-img {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.s-video .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .s-video .play-btn {
    width: 44px;
    height: 32px;
  }
  .s-video .play-btn img {
    max-width: 100%;
  }
}
.s-video__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: none;
}
.s-video__video-youtube {
  width: 730px;
  height: 410px;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 767px) {
  .s-video__video-youtube {
    width: 490px;
    height: 272px;
  }
}
@media only screen and (max-width: 600px) {
  .s-video__video-youtube {
    width: 330px;
    height: 185px;
  }
}
@media only screen and (max-width: 360px) {
  .s-video__video-youtube {
    width: 288px;
    height: 156px;
  }
}
.s-video.seen .s-video__video-wrap {
  transform: translateY(0);
  opacity: 1;
}
.s-benefits {
  margin-top: 160px;
}
@media only screen and (max-width: 992px) {
  .s-benefits {
    margin-top: 96px;
  }
}
.s-benefits__title {
  max-width: 350px;
}
@media only screen and (max-width: 992px) {
  .s-benefits__title {
    text-align: center;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 992px) {
  .s-benefits__description {
    text-align: center;
    margin-top: 480px;
  }
}
@media only screen and (max-width: 600px) {
  .s-benefits__description {
    margin-top: 380px;
  }
}
@media only screen and (max-width: 992px) {
  .s-benefits__btn-wrap {
    display: flex;
    justify-content: center;
  }
}
.s-benefits__content {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .s-benefits__content {
    align-items: flex-start;
  }
}
.s-benefits__text-block {
  width: 450px;
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 992px) {
  .s-benefits__text-block {
    width: 100%;
  }
}
.s-benefits__grafic-wrap {
  width: calc(100% - 450px);
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  .s-benefits__grafic-wrap {
    width: 0;
    padding-top: 240px;
  }
}
@media only screen and (max-width: 600px) {
  .s-benefits__grafic-wrap {
    padding-top: 130px;
    transform: translateX(215px);
  }
}
@media only screen and (max-width: 376px) {
  .s-benefits__grafic-wrap {
    padding-top: 160px;
  }
}
.s-benefits__circles {
  position: relative;
  margin-right: 68px;
}
@media only screen and (max-width: 1080px) {
  .s-benefits__circles {
    margin-right: 0;
  }
}
.s-benefits__circle--main {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  position: relative;
  z-index: 10;
}
@media only screen and (max-width: 600px) {
  .s-benefits__circle--main {
    opacity: 0 !important;
  }
}
.s-benefits__circle--main p {
  font-weight: 600;
  margin-bottom: 0;
}
.s-benefits__circle-text {
  position: absolute;
  font-size: 16px;
  line-height: 141.2%;
  letter-spacing: 0.41px;
  background: #feffff;
  border: 1px solid #e1e1e5;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.04);
  border-radius: 24px;
  padding: 8px 16px;
}
.s-benefits__circle-text:after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 4px solid #84c9a4;
  position: absolute;
  right: -28px;
  top: 10px;
  border-radius: 50%;
}
.s-benefits__circle-text:nth-child(1) {
  top: -15px;
  left: -125px;
}
.s-benefits__circle-text:nth-child(2) {
  top: 57px;
  left: -232px;
}
.s-benefits__circle-text:nth-child(3) {
  top: 129px;
  left: -202px;
}
.s-benefits__circle-text:nth-child(4) {
  top: 201px;
  left: -240px;
}
.s-benefits__circle-text:nth-child(5) {
  top: 273px;
  left: -234px;
}
.s-benefits__circle-text:nth-child(6) {
  top: 345px;
  left: -120px;
}
@media only screen and (max-width: 600px) {
  .s-benefits__circle-text:nth-child(1) {
    top: 24px;
    left: -196px;
  }
  .s-benefits__circle-text:nth-child(2) {
    top: 80px;
    left: -246px;
  }
  .s-benefits__circle-text:nth-child(3) {
    top: 136px;
    left: -202px;
  }
  .s-benefits__circle-text:nth-child(4) {
    top: 192px;
    left: -242px;
  }
  .s-benefits__circle-text:nth-child(5) {
    top: 248px;
    left: -248px;
  }
  .s-benefits__circle-text:nth-child(6) {
    top: 304px;
    left: -194px;
  }
}
.s-benefits__circle--second {
  position: absolute;
  width: 376px;
  height: 376px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #eaf6f2;
  border: 2px dashed #bedbcd;
  border-radius: 50%;
  z-index: 9;
}
.s-benefits .s-benefits__circle--third {
  position: absolute;
  width: 756px;
  height: 756px;
  left: -286px;
  top: -286px;
  background-color: #f0f9f6;
  border: 2px dashed #d7eee3;
  border-radius: 50%;
  z-index: 8;
}
.s-benefits .s-benefits__circle--third .s-benefits__circle-dot {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 4px solid #e2f2ee;
  position: absolute;
  border-radius: 50%;
}
.s-benefits .s-benefits__circle--third .s-benefits__circle-dot:nth-child(1) {
  top: 40px;
  right: 180px;
}
.s-benefits .s-benefits__circle--third .s-benefits__circle-dot:nth-child(2) {
  bottom: 40px;
  left: 176px;
}
.s-benefits .s-benefits__circle--third .s-benefits__circle-dot:nth-child(3) {
  bottom: 140px;
  right: 66px;
}
.s-benefits .s-benefits__circle--fourth {
  position: absolute;
  width: 1266px;
  height: 1266px;
  left: -540px;
  top: -540px;
  background-color: #f6fbfa;
  border: 2px dashed #def1e7;
  border-radius: 50%;
  z-index: 7;
}
.s-benefits .s-benefits__circle--fourth .s-benefits__circle-dot {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 4px solid #e2f2ee;
  position: absolute;
  border-radius: 50%;
}
.s-benefits .s-benefits__circle--fourth .s-benefits__circle-dot:nth-child(1) {
  top: 0;
  right: 510px;
}
.s-benefits .s-benefits__circle--fourth .s-benefits__circle-dot:nth-child(2) {
  bottom: 318px;
  left: 66px;
}
.s-benefits .s-benefits__circle--fourth .s-benefits__circle-dot:nth-child(3) {
  bottom: 44px;
  right: 364px;
}
.s-benefits .s-benefits__circle--main,
.s-benefits .s-benefits__circle--second,
.s-benefits .s-benefits__circle--third,
.s-benefits .s-benefits__circle--fourth {
  opacity: 0;
}
@media only screen and (max-width: 992px) {
  .s-benefits .s-benefits__circle--main,
  .s-benefits .s-benefits__circle--second,
  .s-benefits .s-benefits__circle--third,
  .s-benefits .s-benefits__circle--fourth {
    opacity: 1;
  }
}
.s-benefits .s-benefits__circle--main {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0;
}
.s-benefits .s-benefits__circle--second {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0.3s;
}
.s-benefits .s-benefits__circle--third {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0.6s;
}
.s-benefits .s-benefits__circle--fourth {
  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: 0.8s;
}
.s-benefits .s-benefits__circle--second .s-benefits__circle-text {
  opacity: 0;
}
@media only screen and (max-width: 992px) {
  .s-benefits .s-benefits__circle--second .s-benefits__circle-text {
    opacity: 1;
  }
}
.s-benefits .s-benefits__circle--second .s-benefits__circle-text:nth-child(1) {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 1s;
}
.s-benefits .s-benefits__circle--second .s-benefits__circle-text:nth-child(2) {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 1.2s;
}
.s-benefits .s-benefits__circle--second .s-benefits__circle-text:nth-child(3) {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 1.4s;
}
.s-benefits .s-benefits__circle--second .s-benefits__circle-text:nth-child(4) {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 1.6s;
}
.s-benefits .s-benefits__circle--second .s-benefits__circle-text:nth-child(5) {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 1.8s;
}
.s-benefits .s-benefits__circle--second .s-benefits__circle-text:nth-child(6) {
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 2s;
}
.s-benefits.seen .s-benefits__circle--main,
.s-benefits.seen .s-benefits__circle--second,
.s-benefits.seen .s-benefits__circle--third,
.s-benefits.seen .s-benefits__circle--fourth {
  opacity: 1;
}
.s-benefits.seen .s-benefits__circle-text {
  opacity: 1 !important;
}
.s-benefits.seen .s-benefits__circle--third,
.s-benefits.seen .s-benefits__circle--fourth {
  animation-name: circleAnimation;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@-moz-keyframes circleAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes circleAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes circleAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes circleAnimation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.s-features {
  position: relative;
  z-index: 2;
  margin-top: 149px;
}
@media only screen and (max-width: 992px) {
  .s-features {
    margin-top: 96px;
  }
}
.s-features__title {
  text-align: center;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 34px;
}
@media only screen and (max-width: 600px) {
  .s-features__title {
    margin-bottom: 10px;
  }
}
.s-features__blocks-wrap {
  display: flex;
  width: calc(100% + 30px);
  margin-left: -15px;
}
@media only screen and (max-width: 767px) {
  .s-features__blocks-wrap {
    flex-wrap: wrap;
  }
}
.s-features__block {
  width: calc(50% - 30px);
  margin: 15px;
}
@media only screen and (max-width: 767px) {
  .s-features__block {
    width: calc(100% - 30px);
  }
}
.s-features__block:nth-child(1) .s-features__block-title {
  background-color: #5ec4c6;
}
.s-features__block:nth-child(1) .collapsible .collapsible-item {
  transform: translateX(-50%);
}
.s-features__block:nth-child(1) .custom-list li:before {
  border: 2px solid #5ec4c6;
}
.s-features__block:nth-child(2) .s-features__block-title {
  background-color: #84c9a4;
}
.s-features__block:nth-child(2) .collapsible .collapsible-item {
  transform: translateX(50%);
}
.s-features__block:nth-child(2) .custom-list li:before {
  border: 2px solid #84c9a4;
}
.s-features__block-title {
  padding: 19px 24px;
  border-radius: 12px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .s-features__block-title {
    padding: 14px 24px;
    margin-bottom: 8px;
  }
}
.s-features .collapsible .collapsible-item {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  opacity: 0;
}
.s-features .collapsible .collapsible-item:nth-child(1) {
  transition-delay: 0;
}
.s-features .collapsible .collapsible-item:nth-child(2) {
  transition-delay: 0.3s;
}
.s-features .collapsible .collapsible-item:nth-child(3) {
  transition-delay: 0.6s;
}
.s-features .collapsible .collapsible-item:nth-child(4) {
  transition-delay: 0.9s;
}
.s-features .collapsible .collapsible-item:nth-child(5) {
  transition-delay: 1.2s;
}
.s-features .collapsible .collapsible-item:nth-child(6) {
  transition-delay: 1.5s;
}
.s-features .collapsible .collapsible-item:nth-child(7) {
  transition-delay: 1.8s;
}
.s-features .collapsible .collapsible-item:nth-child(8) {
  transition-delay: 2.1s;
}
.s-features .collapsible .collapsible-item:nth-child(9) {
  transition-delay: 2.4s;
}
.s-features .collapsible .collapsible-item:nth-child(10) {
  transition-delay: 2.7s;
}
.s-features.seen .collapsible .collapsible-item {
  opacity: 1;
  transform: translateX(0);
}
.s-banner {
  position: relative;
}
.s-banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #20233f;
  transform: skewY(5deg);
}
.s-banner__overlay img {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.s-banner__content {
  position: relative;
  z-index: 2;
  margin-top: 120px;
  margin-bottom: 120px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 992px) {
  .s-banner__content {
    margin-top: 98px;
    margin-bottom: 98px;
  }
}
.s-banner__block--img {
  width: 52%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .s-banner__block--img {
    position: absolute;
    left: 50%;
    top: 130px;
    transform: translateX(-50%);
    width: 100%;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .s-banner__block--img {
    width: 330px;
    top: -20px;
  }
}
.s-banner__block--text {
  width: 40%;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .s-banner__block--text {
    width: 100%;
  }
}
.s-banner__img {
  max-width: 100%;
}
.s-banner .description,
.s-banner__text,
.s-banner__title {
  color: #fff;
}
@media only screen and (max-width: 992px) {
  .s-banner .description,
  .s-banner__text,
  .s-banner__title {
    text-align: center;
  }
}
.s-banner__title {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}
.s-banner .description {
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) {
  .s-banner .description {
    margin-top: 640px;
  }
}
@media only screen and (max-width: 600px) {
  .s-banner .description {
    margin-top: 386px;
  }
}
.s-banner .description span {
  color: #3ebb78;
}
.s-banner .btn-custom--green {
  margin-right: 20px;
}
@media only screen and (max-width: 600px) {
  .s-banner .btn-custom--green {
    margin-right: auto;
  }
}
@media only screen and (max-width: 992px) {
  .s-banner__btns-wrap {
    text-align: center;
  }
}
.s-banner .bannerArrow1,
.s-banner .bannerArrow2,
.s-banner .bannerArrow3,
.s-banner .bannerArrow4,
.s-banner .bannerPart1,
.s-banner .bannerPart2,
.s-banner .bannerPart3,
.s-banner .bannerPart4 {
  opacity: 0;
}
@media only screen and (max-width: 992px) {
  .s-banner .bannerArrow1,
  .s-banner .bannerArrow2,
  .s-banner .bannerArrow3,
  .s-banner .bannerArrow4,
  .s-banner .bannerPart1,
  .s-banner .bannerPart2,
  .s-banner .bannerPart3,
  .s-banner .bannerPart4 {
    opacity: 1;
  }
}
.s-banner .bannerPart1 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0;
}
.s-banner .bannerArrow1 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0.4s;
}
.s-banner .bannerPart2 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0.8s;
}
.s-banner .bannerArrow2 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 1.2s;
}
.s-banner .bannerPart3 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 1.6s;
}
.s-banner .bannerArrow3 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 2s;
}
.s-banner .bannerPart4 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 2.4s;
}
.s-banner .bannerArrow4 {
  transition-property: opacity;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 2.8s;
}
.s-banner.seen .bannerArrow1,
.s-banner.seen .bannerArrow2,
.s-banner.seen .bannerArrow3,
.s-banner.seen .bannerArrow4,
.s-banner.seen .bannerPart1,
.s-banner.seen .bannerPart2,
.s-banner.seen .bannerPart3,
.s-banner.seen .bannerPart4 {
  opacity: 1;
}
.s-tab {
  margin-top: 128px;
  position: relative;
  z-index: 3;
}
@media only screen and (max-width: 600px) {
  .s-tab {
    margin-top: 56px;
  }
}
.s-tab__overlay {
  position: absolute;
  left: 50%;
  top: 120px;
  transform: translateX(-50%) rotate(-200deg);
  display: none;
}
@media only screen and (max-width: 767px) {
  .s-tab__overlay {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .s-tab__overlay {
    top: 50px;
  }
}
@media only screen and (max-width: 520px) {
  .s-tab__overlay {
    left: 80%;
    top: 70px;
  }
}
@media only screen and (max-width: 420px) {
  .s-tab__overlay {
    top: 110px;
  }
}
@media only screen and (max-width: 340px) {
  .s-tab__overlay {
    top: 180px;
  }
}
.s-tab .container {
  position: relative;
  z-index: 2;
}
.s-tab__title {
  text-align: center;
}
.s-tab .description {
  max-width: 730px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.s-tab__content {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  justify-content: space-between;
}
@media only screen and (max-width: 992px) {
  .s-tab__content {
    flex-wrap: wrap;
  }
}
.s-tab__img {
  display: none;
}
.s-tab__img.active {
  display: block;
}
.s-tab__img.active:not(.s-tab__img--desctp) {
  display: none;
}
@media only screen and (max-width: 600px) {
  .s-tab__img.active:not(.s-tab__img--desctp) {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .s-tab__img.active:not(.s-tab__img--mob) {
    display: none;
  }
}
.s-tab__tab-content {
  display: none;
}
.s-tab__tab-content.active {
  display: block;
}
.s-tab__block--img {
  width: 45%;
  display: flex;
  justify-content: flex-end;
}
.s-tab__block--img img {
  max-width: 547px;
}
@media only screen and (max-width: 992px) {
  .s-tab__block--img {
    padding-top: 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    order: 2;
  }
  .s-tab__block--img img {
    max-width: 100%;
  }
}
.s-tab__block--tabs {
  width: 50%;
}
@media only screen and (max-width: 992px) {
  .s-tab__block--tabs {
    width: 100%;
    order: 1;
  }
}
.s-tab__btns-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 44px;
}
@media only screen and (max-width: 600px) {
  .s-tab__btns-wrap {
    justify-content: space-between;
    margin-bottom: 24px;
  }
}
.s-tab__content-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 141.2%;
  letter-spacing: 0.41px;
  color: #20233f;
  margin-bottom: 16px;
}
@media only screen and (max-width: 600px) {
  .s-tab__content-title {
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 0;
  }
}
.s-tab__content-block {
  margin-bottom: 42px;
}
@media only screen and (max-width: 600px) {
  .s-tab__content-block {
    margin-bottom: 16px;
  }
}
.s-slider {
  position: relative;
  margin-bottom: 60px;
  z-index: 3;
}
.s-slider .s-slider-overlay-1 {
  position: absolute;
  top: 0;
  left: -290px;
}
@media only screen and (max-width: 600px) {
  .s-slider .s-slider-overlay-1 img {
    width: 500px;
  }
}
.s-slider .s-slider-overlay-2 {
  position: absolute;
  top: 50px;
  right: -100px;
}
@media only screen and (max-width: 1500px) {
  .s-slider .s-slider-overlay-2 {
    right: auto;
    left: 400px;
  }
}
@media only screen and (max-width: 600px) {
  .s-slider .s-slider-overlay-2 {
    left: -100px;
    top: 145px;
  }
  .s-slider .s-slider-overlay-2 img {
    width: 960px;
  }
}
.s-slider__content {
  position: relative;
  z-index: 2;
  margin-top: 100px;
}
.s-slider__title {
  max-width: 520px;
}
@media only screen and (max-width: 600px) {
  .s-slider__title {
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  .s-slider__text {
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 0.41px;
  }
}
.s-slider__slider {
  padding-bottom: 100px;
  margin-top: 64px;
  margin-bottom: 120px;
  max-width: 825px;
}
@media only screen and (max-width: 600px) {
  .s-slider__slider {
    margin-top: 28px;
    padding-bottom: 80px;
    margin-bottom: 0;
  }
}
.s-slider__slide-wrap {
  display: flex;
  align-items: flex-end;
}
.s-slider__slide-wrap:nth-child(1) .s-slider__slide {
  background: url("../img/slide-bg-1.svg") 100% 0 no-repeat;
  background-color: #fff;
  background-size: cover;
}
.s-slider__slide-wrap:nth-child(2) .s-slider__slide {
  background: url("../img/slide-bg-2.svg") 100% 0 no-repeat;
  background-color: #fff;
  background-size: cover;
}
.s-slider__slide-wrap:nth-child(3) .s-slider__slide {
  background: url("../img/slide-bg-3.svg") 100% 0 no-repeat;
  background-color: #fff;
  background-size: cover;
}
.s-slider__slide-wrap:nth-child(4) .s-slider__slide {
  background: url("../img/slide-bg-4.svg") 100% 0 no-repeat;
  background-color: #fff;
  background-size: cover;
}
.s-slider__slide-wrap:nth-child(5) .s-slider__slide {
  background: url("../img/slide-bg-5.svg") 100% 0 no-repeat;
  background-color: #fff;
  background-size: cover;
}
.s-slider__slide-wrap:nth-child(6) .s-slider__slide {
  background: url("../img/slide-bg-6.svg") 100% 0 no-repeat;
  background-color: #fff;
  background-size: cover;
}
.s-slider__slide {
  padding: 72px;
  border-radius: 32px;
  background: url("../img/slide-bg-1.svg") 100% 0 no-repeat;
  background-size: cover;
  background-color: #fff;
  box-shadow: 0px 16px 16px rgba(142,142,142,0.1);
  transition: all 0.3s ease;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .s-slider__slide {
    padding: 32px;
    background-size: cover;
  }
}
.s-slider .slide__person-wrap {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.s-slider .slide__person-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: #e1e1e5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-slider .slide__person-icon img {
  max-width: 100%;
  max-height: 100%;
}
.s-slider .slide__person-text {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 141.2%;
  letter-spacing: 0.41px;
}
@media only screen and (max-width: 600px) {
  .s-slider .slide__person-text {
    font-size: 16px;
    line-height: 141.2%;
    letter-spacing: 0.41px;
  }
}
.s-slider .prev-btn {
  width: 56px;
  height: 56px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 80px;
}
@media only screen and (max-width: 600px) {
  .s-slider .prev-btn {
    left: 50%;
    transform: translateX(-68px);
  }
}
.s-slider .prev-btn:before {
  transform: rotate(-180deg);
}
.s-slider .next-btn {
  width: 56px;
  height: 56px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
}
@media only screen and (max-width: 600px) {
  .s-slider .next-btn {
    left: 50%;
    transform: translateX(12px);
  }
}
.s-slider .slick-slide:not(.slick-current) {
  opacity: 0.6;
}
.s-slider .slick-slide:not(.slick-current) .s-slider__slide {
  transform: scale(0.9, 0.9);
}
.s-slider .slick-track {
  display: flex;
  align-items: center;
}
.s-slider .slick-list {
  overflow: visible;
}
.s-slider .slick-disabled {
  cursor: auto !important;
}
.s-slider .slick-disabled path {
  fill: #e1e1e5 !important;
}
.s-slider .slick-disabled:before {
  opacity: 0.6 !important;
}
.s-slider .slick-arrow {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.s-slider .slick-arrow:before {
  content: '';
  display: block;
  background: url("../img/arrow-slide.svg") center no-repeat;
  background-size: cover;
  width: 28px;
  height: 28px;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}
.s-slider .slick-arrow path {
  transition: all 0.3s ease;
}
.s-slider .slick-arrow:hover path {
  fill: #20233f;
}
.s-slider .slick-arrow:hover:before {
  opacity: 1;
}
